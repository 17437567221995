import React from "react";
import { Link } from "react-router-dom";
import "./RoutePage.css"; // A separate CSS file for route pages styling
import ArticleType1 from "../components/ArticleTypeA";

const ReactJS = () => {
  return (
    <div className="route-page">
      <div className="top-bar-route">
        <Link to="/" className="back-home-link">
          ← Back to Home
        </Link>
      </div>
      <div className="route-content">
        <ArticleType1></ArticleType1>
      </div>
    </div>
  );
};

export default ReactJS;
