import React from "react";
import "./UnderConstruction.css";

function UnderConstruction() {
  return (
    <div className="under-construction">
      <h1>🚧 Under Construction 🚧</h1>
      <p>We are currently working on this site. Please check back soon!</p>
    </div>
  );
}

export default UnderConstruction;
