import React from "react";
import "./DeveloperPresentation.css"; // Add specific styling

function DeveloperPresentation() {
  return (
    <div className="developer-presentation">
      <img
        className="developer-image"
        src="https://via.placeholder.com/150"
        alt="Developer"
      />
      <div className="developer-info">
        <h2>John Doe</h2> {/* Replace with your name */}
        <h3>Software Developer</h3> {/* Job title */}
        <p>
          Passionate software developer specializing in front-end and back-end
          technologies. Experienced in building scalable web applications and
          keen on modern web development practices.
        </p>
      </div>
    </div>
  );
}

export default DeveloperPresentation;
