import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import DeveloperPresentation from "./components/DeveloperPresentation";
import UnderConstruction from "./components/UnderConstruction";
import "./App.css";
import ReactJS from "./pages/ReactJs";

function App() {
  const [theme, setTheme] = useState("dark");
  const [isUnderConstruction, setIsUnderConstruction] = useState(true);

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      setTheme(savedTheme);
    }
  }, []);

  const toggleTheme = (selectedTheme) => {
    setTheme(selectedTheme);
    localStorage.setItem("theme", selectedTheme);
  };

  if (isUnderConstruction) {
    return <UnderConstruction />;
  }

  return (
    <div className={`app-container ${theme}`}>
      <div className="top-bar">
        <div className="theme-switcher">
          <img
            src="https://img.icons8.com/ios-filled/50/ffffff/moon-symbol.png"
            alt="Dark Mode"
            className={theme === "dark" ? "active" : ""}
            onClick={() => toggleTheme("dark")}
          />
          <img
            src="https://img.icons8.com/ios-filled/50/000000/sun.png"
            alt="Light Mode"
            className={theme === "light" ? "active" : ""}
            onClick={() => toggleTheme("light")}
          />
        </div>
      </div>

      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <div>
                <header>
                  <h1 className="title">Title</h1>
                </header>

                <DeveloperPresentation />

                <div className="routes-grid">
                  <Link to="/python" className={`route-square ${theme}`}>
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/c/c3/Python-logo-notext.svg"
                      alt="Python Logo"
                    />
                    <span>Python</span>
                  </Link>

                  <Link to="/javascript" className={`route-square ${theme}`}>
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/6/6a/JavaScript-logo.png"
                      alt="JavaScript Logo"
                    />
                    <span>JavaScript</span>
                  </Link>

                  <Link to="/android" className={`route-square ${theme}`}>
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/d/d7/Android_robot.svg"
                      alt="Android Logo"
                    />
                    <span>Android</span>
                  </Link>

                  <Link to="/reactjs" className={`route-square ${theme}`}>
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg"
                      alt="ReactJS Logo"
                    />
                    <span>ReactJs</span>
                  </Link>

                  <Link to="/nodejs" className={`route-square ${theme}`}>
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/d/d9/Node.js_logo.svg"
                      alt="NodeJS Logo"
                    />
                    <span>ReactJs</span>
                  </Link>

                  <Link to="/flutter" className={`route-square ${theme}`}>
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/4/44/Google-flutter-logo.svg"
                      alt="Flutter Logo"
                    />
                    <span>ReactJs</span>
                  </Link>
                </div>
              </div>
            }
          />

          <Route path="/python" element={<ReactJS />} />
          <Route path="/javascript" element={<ReactJS />} />
          <Route path="/android" element={<ReactJS />} />
          <Route path="/reactjs" element={<ReactJS />} />
          <Route path="/nodejs" element={<ReactJS />} />
          <Route path="/flutter" element={<ReactJS />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
